import React, { useEffect } from 'react';
import './calendarStyles.css';




function CalendarPlanificacion14 () {

    return (

        <>
        <div>
               <h2 className='head-master-form'>Agenda tu asesoría</h2>
               <p className='info-text'>Selecciona una fecha para agendar tu asesoría.</p>
            </div>
            <iframe
        className='CalendarStyle' 
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0bQQTCG_MvGYmAXg9qbY0pOOPtXxmU5cevEMMpWS-cEe1r8q4zFGcfwNASpkc6abgTxINt4Qpx?gv=true">

        </iframe>
        </>

    )
    
}

export { CalendarPlanificacion14 }